/**
 * Drop-in replacement for fetch taking care
 */
export default async function makeRequest(url, options, tryReauthenticate = true) {
  // Make request
  const response = await fetch(url, options);

  // If response is 403, need to reauthenticate
  if (tryReauthenticate && response.status === 403) {
    await fetch('/api/v1/users/refresh');
    return makeRequest(url, options, false);
  }

  return response;
}
