function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = ({items}) => {
  return (
    items.length > 0 && (
      <div className="mx-6 h-full max-h-full w-full max-w-full px-2">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    {Object.entries(items[0]).map(([key, value]) => (
                      <th
                        key={key}
                        scope="col"
                        className="sticky top-0 z-10 border-b border-slate-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-bold capitalize text-black backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      {Object.entries(item).map(([key, value]) => (
                        key !== 'identity' && key !== 'install'? <td
                          key={key}
                          className={classNames(
                            index !== items.length - 1
                              ? "border-b border-slate-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 lg:pl-8",
                          )}
                        >
                          {value}
                        </td> : <td
                          key={key}
                          className={classNames(
                            index !== items.length - 1
                              ? "border-b border-slate-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-500 sm:pl-6 lg:pl-8",
                          )}
                        >
                          {key !== 'install'? <button onClick={() => navigator.clipboard.writeText(value)}>Copy</button> : <a href={value}>Click to Install</a>}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Table;